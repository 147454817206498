import { useCustomerIO } from 'providers/CustomerIOProvider';

import { ApiPlaygroundEditDetails } from './ApiPlaygroundEditDetails';

/*
 * -----------------------------------------------------------------------------
 * This page is disabled. It automatically goes with editing the api call config
 * It is still useful because it sets the default values for the editor
 * -----------------------------------------------------------------------------
 */
// TODO maybe it's not that useful anymore after the code cleanup :)

export default function NewApiRequest() {
  const customerIO = useCustomerIO();
  customerIO.page();

  return <ApiPlaygroundEditDetails />;
};
