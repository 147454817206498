import { RiArrowDownSLine, RiArrowUpSLine } from "@remixicon/react";

import { cx } from "utils";


interface IRotatingArrowIconProps {
  rotated: boolean;
  direction: "up" | "down";
  className?: string;
}


export default function RotatingArrowIcon(
  {
    direction,
    rotated,
    className,
  }: IRotatingArrowIconProps
) {
  const props = direction === "up" ?
    {
      Arrow: RiArrowUpSLine,
      rotation: "-rotate-180"
    } :
    {
      Arrow: RiArrowDownSLine,
      rotation: "rotate-180"
    };

  return (
    <props.Arrow
      className={ cx(
        className,
        "transition-transform duration-200 ease-in-out",
        rotated && props.rotation
      ) }
    />
  );
};
