import { MouseEventHandler } from "react";

import { RiPushpinLine, RiPushpinFill, RiUnpinFill } from "@remixicon/react";

import { cx } from "utils";


export default function PinButton(
  {
    pinned,
    onClick,
    className,
  }: {
    pinned: boolean;
    onClick: MouseEventHandler<HTMLDivElement>;
    className?: string;
  }
) {
  return (
    <div
      className={ cx("flex group", className) }
      onClick={ onClick }
    >
      { pinned && (
        <>
          <RiPushpinFill className="size-4 group-hover:hidden" />
          <RiUnpinFill className="size-4 hidden group-hover:block" />
        </>
      )}
      { !pinned && (
        <>
          <RiPushpinLine className="size-4 group-hover:hidden" />
          <RiPushpinFill className="size-4 hidden group-hover:block" />
        </>
      )}
    </div>
  );
};
