import { ComponentType, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Transition } from "@headlessui/react";
import { RiCoinsLine, RiMenuFoldLine, RiMenuUnfoldLine } from "@remixicon/react";

import scraperApi from "api";
import { cx } from "utils";

import { ReactComponent as MobileMenuIcon } from "assets/icons/menu-icon.svg";
import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo.svg";

import Button from "components/Button";
import ExtLink from "components/ExtLink";
import { useFeatureSwitch } from "components/FeatureSwitch";
import { FeatureSwitch } from "components/FeatureSwitch/FeatureSwitches";
import Icons from "components/Icons";
import UserMenu from "components/UserMenu";
import TaggedText from "components/TaggedText";

import { useContactSales } from "hooks/useContactSales";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useSupportWidget } from "hooks/useSupportWidget";

import { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";
import { useUserProvider } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";


const PANEL_WIDTH = "w-[256px]";
const COLLAPSED_WIDTH = "w-[64px]";
const USER_MENU_WIDTH = "w-[270px]";


interface ISidebarItemProps {
  Icon?: ComponentType<any>;
  iconStyle?: string;
  icon?: JSX.Element;
  content?: JSX.Element | string;
  contentPadding?: string;
  collapsed?: boolean;
  disabled?: boolean;
  disableWhenBlocked?: boolean;
  active?: boolean;
  onClick?: () => void;
  featureSwitch?: FeatureSwitch;
}

function SidebarItemContent({ Icon, iconStyle, icon, content, contentPadding = "py-3 pr-4", collapsed, active }: ISidebarItemProps) {
  return (
    <>
      { (Icon || icon) && (
        <div
          className={ cx("pl-6 h-12 flex place-content-center items-center border-l-[3px] dark:border-l-0", active ? "border-l-brandPrimary" : "border-l-transparent") }
        >
          { Icon && <Icon className={ iconStyle } /> }
          { icon }
        </div>
      ) }
      <div className={ cx(
        "w-full font-droid",
        contentPadding,
        "*:transition-opacity *:duration-700",
        collapsed ? "*:opacity-0" : "*:opacity-100"
        ) }
      >
        { typeof content === "string" ? <div className="pl-4 w-full whitespace-nowrap">{ content }</div> : <>{ content }</> }
      </div>
    </>
  );
}

function SidebarItem({ Icon, iconStyle, icon, content, contentPadding, collapsed }: ISidebarItemProps) {
  return (
    <div className="flex flex-row items-center w-full">
      <SidebarItemContent
        Icon={ Icon }
        iconStyle={ iconStyle }
        icon={ icon }
        content={ content }
        contentPadding={ contentPadding }
        collapsed={ collapsed }
      />
    </div>
  );
}

function useReallyDisabled(disabled?: boolean, disableWhenBlocked?: boolean) {
  const user = useUser();
  return disabled || (disableWhenBlocked && user?.isBlocked);
}

function SidebarNavLink({ disabled, disableWhenBlocked, to, onClick, addLocationToState, featureSwitch, ...props }: ISidebarItemProps & { to: string, addLocationToState?: boolean }) {
  const location = useLocation();
  const isFeatureEnabled = useFeatureSwitch(featureSwitch);
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  if (!isFeatureEnabled) {
    return <></>;
  }

  return (
    <NavLink
      to={ to }
      className={ ({ isActive }) => cx(
        "flex flex-row items-center w-full transition-colors duration-50",
        (!reallyDisabled && isActive) ? "text-brandPrimary dark:text-white dark:bg-primary-600" : "hover:bg-slate-50 dark:hover:bg-primary-100 hover:text-gray-700 dark:hover:text-neutral-900"
      ) }
      onClick={ onClick }
      state={ { backgroundLocation: addLocationToState ? location : undefined } }
    >
      { ({ isActive }) => (
        <SidebarItemContent
          { ...props }
          active={ isActive }
        />
      )}
    </NavLink>
  );
}

function SidebarExtLink({ disabled, disableWhenBlocked, href, tracked = false, onClick, ...props }: ISidebarItemProps & { href: string, tracked?: boolean }) {
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  return (
    <ExtLink
      to={ href }
      className={ cx(
        "flex flex-row items-center w-full",
        !reallyDisabled && "hover:bg-slate-50 dark:hover:bg-primary-100 hover:text-gray-700 dark:hover:text-neutral-900"
      ) }
      onClick={ onClick }
      tracked
    >
      <SidebarItemContent { ...props } />
    </ExtLink>
  )
}

function SidebarAction({ disabled, disableWhenBlocked, onClick, ...props }: ISidebarItemProps) {
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  return (
    <div
      className={ cx(
        "flex flex-row items-center w-full cursor-pointer",
        !reallyDisabled && "hover:bg-slate-50 dark:hover:bg-primary-100 hover:text-gray-700"  // TODO do not change text color in new design (dark mode)
      ) }
      onClick={ onClick }
    >
      <SidebarItemContent { ...props } />
    </div>
  );
}

const UpgradeButton = ({ onClickCallback }: { onClickCallback?: () => void }) => {

  const { subscription } = useUserProvider();

  if (subscription?.plan_id !== "free") {
    return <></>;
  }

  return (
    <div className="flex flex-col items-center justify-center ml-4 my-4 p-4 space-y-4 bg-brandLightest dark:bg-primary-50">
      <p className="text-sm text-brandDarkest dark:text-primary-800 text-center max-w-[220px] mb-2">
        <TaggedText message="Need more time to test and maximize your experience? [Book a call|book_sales_call] and get an extended trial!" />
      </p>
      <Button
        text="Book a call"
        className="button button-primary"
        size="MD"
        href="/book-sales-call"
        shouldPassBackgroundLocation
        onClick={ onClickCallback }
        focusable={ false }
      />
    </div>
  );
};

function StatusBadge({ xOffset, yOffset, className }: { xOffset: string, yOffset: string, className?: string }) {
  const [ status, setStatus ] = useState<Status>();
  const fetchStatus = useCallback(async (controller: AbortController) => {
    const status = await scraperApi.status.issues({ signal: controller.signal });
    setStatus(status);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetchStatus(controller);

    return () => controller.abort();
  }, [ fetchStatus ]);

  return (
    <Transition
      show={ ((status?.numActiveIssues || 0) > 0) }
    >
      <div className={ cx(
        "flex items-center justify-center w-4 h-4 text-xs text-white bg-brandPrimary-500 dark:bg-warning-600 rounded-full absolute",
        "transition ease-in-out duration-100 data-[closed]:opacity-0 data-[closed]:scale-50",
        xOffset,
        yOffset,
        className
      ) }>
        { status?.numActiveIssues }
      </div>
    </Transition>
  );
}

interface Status {
  mostRecentIssue: any;
  numActiveIssues: number;
  numRecentlyResolvedIssues: number;
}

interface ILogoAndIconsProps {
  mobile?: boolean;
  toggleMobileMenu?: () => void;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  showCollapseIcon?: boolean;
}

function LogoAndIcons({ mobile, toggleMobileMenu, collapsed, toggleCollapsed, showCollapseIcon = true }: ILogoAndIconsProps) {
  if (mobile) {
    return (
      <div className="flex items-center justify-between">
        <ScraperAPILogo/>
        <MobileMenuIcon
          className="cursor-pointer text-lightGray dark:text-neutral-500 hover:text-gray dark:hover:text-neutral-600 transition-colors"
          onClick={ toggleMobileMenu }
        />
      </div>
    );
  } else {
    const CollapseIcon = collapsed ? RiMenuUnfoldLine : RiMenuFoldLine;

    return (
      <div
        className="flex items-center justify-start min-w-8 min-h-6 pl-1 relative">
        <Transition show={ !collapsed }>
          <ScraperAPILogo className="h-6 w-min flex-shrink-0 transition-opacity duration-700 data-[closed]:opacity-0"/>
        </Transition>
        { showCollapseIcon &&
            <CollapseIcon
                className="absolute right-1 cursor-pointer text-lightGray dark:text-neutral-300 hover:text-gray dark:hover:text-neutral-400 duration-200 transition-colors"
                onClick={ toggleCollapsed }
            />
        }
      </div>
    );
  }
}

function ContactSupportSidebarAction({ collapsed }: { collapsed: boolean }) {
  const supportWidget = useSupportWidget();
  const user = useUser();

  return (
    <SidebarAction
      content="Contact Support"
      Icon={ Icons.Support }
      iconStyle="w-5 dark:w-4 h-5 dark:h-4"
      collapsed={ collapsed }
      onClick={ () => {
        supportWidget?.hideSalesForm?.();
        supportWidget?.showSupportForm(user?.email);
      } }
    />
  );
}

function ContactSalesSidebarAction({ collapsed }: { collapsed: boolean }) {
  const { contactSalesFn } = useContactSales();
  const supportWidget = useSupportWidget();

  return (
    <SidebarAction
      content="Contact Sales"
      Icon={ Icons.Sales }
      iconStyle="w-5 dark:w-4 h-5 dark:h-4"
      collapsed={ collapsed }
      onClick={ () => { supportWidget?.hideSupportForm?.(); contactSalesFn(); } }
    />
  );
}

const WrapForMobile = ({ mobile, show, mobileMenuToggleCallback, children }: { mobile: boolean, show: boolean, mobileMenuToggleCallback: () => void, children: ReactNode }) => {
  const refContent = useRef<any | null>(null);
  const refHeader = useRef<any | null>(null);
  useEffect(() => {
    if (mobile && show) {
      const handleClick = (e: MouseEvent) => {
        const inContent = refContent.current && refContent.current.contains(e.target as Node);
        const inHeader = refHeader.current && refHeader.current.contains(e.target as Node);
        if (!inHeader && !inContent) {
          mobileMenuToggleCallback();
        }
      };
      // Don't use 'click' here. inHeader will be always false. The ref won't contain the target.
      document.addEventListener('mousedown', handleClick);
      return () => document.removeEventListener('mousedown', handleClick);
    }
  }, [ show, mobile, mobileMenuToggleCallback ]);

  if (mobile) {
    return (
      <>
        <div ref={refHeader} className="select-none border-b border-borderColor dark:border-neutral-200 px-6 pt-3 pb-4 border-l-[3px] dark:border-l-0 border-l-transparent">
          <LogoAndIcons mobile toggleMobileMenu={mobileMenuToggleCallback}/>
        </div>

        <div ref={refContent}>
          <Transition show={ show } >
            <div className={ cx(
              "absolute top-0 left-0 h-full overflow-y-auto z-10 bg-white dark:bg-neutral-50",
              "transition-transform ease-in-out duration-500 data-[closed]:-translate-x-full"
            ) }
            >
              { children }
            </div>
          </Transition>
        </div>
      </>
    );
  } else {
    return <>{ children }</>;
  }
};

export default function Sidebar() {
  const [ collapsed, setCollapsed ] = useLocalStorage("saSidebarCollapsed", false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [ showMobileMenu, setShowMobileMenu ] = useState(false);
  const { newDashboardDesignSetting } = useNewDashboardDesign();
  const user = useUser();

  const reallyCollapsed = useMemo(() => collapsed && !isMobile, [ collapsed, isMobile ]);

  const isShortMenu = useMediaQuery({ query: "(max-height: 860px)" });

  return (
    <WrapForMobile mobile={ isMobile } show={showMobileMenu} mobileMenuToggleCallback={() => setShowMobileMenu(x => !x)}>
      <div className={
        cx(
          "relative group",
          "flex flex-col h-full",
          "text-gray dark:text-neutral-600 bg-white dark:bg-neutral-50 border-r border-borderColor dark:border-neutral-200",
          "overflow-y-auto overflow-x-hidden",
          "transition-all duration-300 ease-in-out",
          reallyCollapsed ? COLLAPSED_WIDTH : PANEL_WIDTH)
      }
      >
        <div className={ cx(
          "px-4 py-4 z-10 bg-white dark:bg-neutral-50",
          !isMobile && "sticky top-0"
          ) }
        >
          <LogoAndIcons
            showCollapseIcon={ !isMobile }
            toggleCollapsed={ () => setCollapsed(c => !c) }
            collapsed={ reallyCollapsed }
          />
        </div>

        <div className="flex flex-col h-full justify-between">
          <div className="space-y-2">
            <SidebarNavLink
              to="/"
              content={ (newDashboardDesignSetting && user?.isFreeTrial) ? "Get started" : "Dashboard" }
              Icon={ Icons.Dashboard }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              featureSwitch="REACT_APP_API_PLAYGROUND_USERS"
              to="/apiplayground"
              content="API playground"
              Icon={ Icons.APIPlayground }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              disableWhenBlocked
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              to="/projects"
              content="DataPipeline"
              Icon={ Icons.DataPipeline }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              disableWhenBlocked
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              to="/billing"
              content="Billing"
              Icon={ Icons.Billing }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />
          </div>

          <div className="relative">
            { !isShortMenu && (
              <SidebarItem
                disabled
                collapsed={ reallyCollapsed }
                content={ (
                  <div className="w-[223px]">
                    <UpgradeButton/>
                  </div>
                ) }
              />
            ) }

            <div className="space-y-2 pb-4">
              <SidebarExtLink
                href="https://status.scraperapi.com"
                content={ <div className="flex flex-row gap-x-3 relative">
                  <div className="pl-4 whitespace-nowrap">Status Page</div>
                  { !reallyCollapsed && <StatusBadge xOffset="left-[108px]" yOffset="top-[-10px]"/> }
                </div> }
                icon={ <>
                  <Icons.Status className="relative w-5 dark:w-4 h-5 dark:h-4"/>
                  { reallyCollapsed && <StatusBadge xOffset="right-[-14px]" yOffset="top-0"/> }
                </> }
                collapsed={ reallyCollapsed }
              />

              <SidebarExtLink
                href="https://www.scraperapi.com/documentation/"
                content="Documentation"
                Icon={ Icons.Documentation }
                iconStyle="w-5 dark:w-4 h-5 dark:h-4"
                collapsed={ reallyCollapsed }
              />

              <SidebarExtLink
                href="https://www.scraperapi.com/affiliates/"
                content="Referral program"
                Icon={ RiCoinsLine }
                iconStyle="w-5 dark:w-4 h-5 dark:h-4"
                collapsed={ reallyCollapsed }
              />

              <ContactSupportSidebarAction collapsed={ reallyCollapsed }/>

              <ContactSalesSidebarAction collapsed={ reallyCollapsed }/>
            </div>

            <SidebarItem
              content={ <div className="flex flex-row items-center">
                <div className="border-t border-borderColor dark:border-neutral-200 w-full"/>
              </div> }
              contentPadding=""
              disabled
            />

            <UserMenu collapsed={ reallyCollapsed } dropdownWidth={ USER_MENU_WIDTH }/>
          </div>
        </div>
      </div>
    </WrapForMobile>
  );
}
