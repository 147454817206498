import { useContext, useEffect, useState } from "react";

import { UCServiceInfo, UCWindow, UsercentricsContext } from "providers/UsercentricsProvider";


const getServiceInfo = async (serviceName: string) => {
  const allServicesInfo = (await (window as UCWindow).__ucCmp?.getConsentDetails?.())?.services || {};
  return Object.values(allServicesInfo).find(({ name }) => serviceName === name);
};


export function useServiceHasConsent(serviceName: string) {

  // TODO if UC is not initialized yet we could also check the local storage but for that we need the encoded service id
  const { isInitialized } = useContext(UsercentricsContext);

  const [ serviceInfo, setServiceInfo ] = useState<UCServiceInfo | undefined>();

  useEffect(() => {
    if (isInitialized) {
      const fetchServiceInfo = async () => {
        const sInfo = await getServiceInfo(serviceName);
        setServiceInfo(sInfo);
      }
      fetchServiceInfo();
    }
  }, [ isInitialized, serviceName, setServiceInfo])

  return serviceInfo?.consent?.given || null;

  // // check local storage in case Usercentrics is not yet initialized
  // const serviceFromLocalStorage = localStorage.find((service) => serviceId === service.id);
  // return serviceFromLocalStorage ? serviceFromLocalStorage.status : null;

}
