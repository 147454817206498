import { sdeDescriptors } from "sdecontent";

import ResourceCard, { Bold, Title } from "./ResourceCard";
import { useQuickStartContext } from "./QuickStartCard";


const getDocsSection = (sdeProjectType: string) => {
  return sdeDescriptors.find(desc => desc.projectType === sdeProjectType)?.docsSection;
};

const getDocsSectionAsync = (sdeProjectType: string) => {
  return sdeDescriptors.find(desc => desc.projectType === sdeProjectType)?.docsSectionAsync;
};

export default function SDEResources() {
  const { programmingLanguage, sdeProjectType } = useQuickStartContext();

  return (
    <>
      <ResourceCard
        title={ <Title>Get started with the <Bold>API Method</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${ programmingLanguage }/making-requests/structured-data-collection-method/${getDocsSection(sdeProjectType)}` }
        tracked
      />

      <ResourceCard
        title={ <Title>Use the <Bold>Async Method</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/async-structured-data-collection-method/${getDocsSectionAsync(sdeProjectType)}` }
        tracked
      />

      <ResourceCard
        title={ <Title>See all available <Bold>Structured Data Endpoints</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/structured-data-collection-method` }
        tracked
      />

      <ResourceCard
        title={ <Title>Let us <Bold>host your Structured Data requests</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/datapipeline/how-to-create-a-new-datapipeline-project/input-and-parameters#input-for-use-case-specific-project-types` }
        tracked
      />
    </>
  );
};
