import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MenuItem } from "@headlessui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipModel,
  ChartOptions,
  ChartTypeRegistry,
  ScatterDataPoint,
  BubbleDataPoint
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { parse } from "json2csv";
import { saveAs } from "file-saver";

import { ReactComponent as ChartStatsIcon } from "assets/icons/chart_stats_icon.svg";
import { ReactComponent as DomainStatsIcon } from "assets/icons/domain_stats_icon.svg";
import { ReactComponent as ReportsDropdownIcon } from "assets/icons/reports_dropdown_icon.svg";

import Menu, { MenuButton, MenuItems } from "components/Menu";
import Select, { SelectOption } from "components/Select";
import Spinner from "components/Spinner";

import { cx } from "utils";

import { DAY } from "utils/timeConstants";
import scraperApi from "api";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type TimeSelectOption = SelectOption<"days" | "hours" | "minutes">;
const TimeSelectOptions: TimeSelectOption[] = [
  { name: "Last Hour", value: "minutes" },
  { name: "Last Day", value: "hours" },
  { name: "Last Week", value: "days" }
];

type SuccessRateTypes = {
  days: SuccessRateType[];
  hours: SuccessRateType[];
  minutes: SuccessRateType[];
};
type SuccessRateType = {
  time: string;
  failures: number;
  successes: number;
  costs: number;
};

const HTMLTooltip = ({
  data,
  position,
  visibility
}: {
  data: TooltipModel<"bar"> | undefined;
  position: { top: number; left: number };
  visibility: boolean;
}) => {
  return (
    <div
      className={cx(
        "absolute p-4 overflow-hidden pointer-events-none transition-all bg-white border shadow duration-300 border-borderColor dark:border-neutral-200 flex flex-col gap-y-4",
        visibility ? "opacity-100" : "opacity-0"
      )}
      style={{ top: position?.top, left: position?.left }}
    >
      <p className="font-bold text-gray dark:text-neutral-600">{data?.title}</p>
      <div className="space-y-2">
        {data?.dataPoints?.map((dataPoint, dataPointIdx) => (
          <div
            key={[ 'div', dataPoint.label, dataPoint.dataset.label, dataPoint.datasetIndex, dataPoint.dataIndex ].join('|')}
            className="flex items-center gap-x-2"
          >
            <div
              className="w-4 h-4"
              style={{
                backgroundColor:
                  data.labelColors[
                    dataPointIdx
                  ].backgroundColor?.toString()
              }}
            />
            <p
              key={[ 'p', dataPoint.label, dataPoint.dataset.label, dataPoint.datasetIndex, dataPoint.dataIndex ].join('|')}
              className="space-x-2"
            >
              <span>{dataPoint.dataset.label}</span>
              <span className="font-bold text-gray dark:text-neutral-600">
                {dataPoint.formattedValue}
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function SuccessRateHistoryGraph() {
  const [tooltipData, setTooltipData] = useState<TooltipModel<"bar">>();
  const [successRateData, setSuccessRateData] = useState<SuccessRateTypes>();

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipPos, setTooltipPos] = useState<{ top: number; left: number }>();

  const [ selectedTime, setSelectedTime ] = useState<TimeSelectOption>(TimeSelectOptions[0]);

  const [generatingReportInProgress, setGeneratingReportInProgress] = useState<boolean>(false);
  // You can open the modal once. One you close it, it won't show up again (until reload)
  // The progress spinner is still there
  // You can remove these on 2023-01-01
  // const [reportInfoModalOpened, setReportInfoModalOpened] = useState<boolean>(false);
  // const [reportInfoModalClosed, setReportInfoModalClosed] = useState<boolean>(false);
  const chartRef = useRef(null);

  // const shouldShowReportInfoModal = reportInfoModalOpened && !reportInfoModalClosed;

  const customTooltip = useCallback(
    (context: {
      chart: ChartJS<
        keyof ChartTypeRegistry,
        (number | ScatterDataPoint | BubbleDataPoint | null)[],
        unknown
      >;
      tooltip: TooltipModel<"bar">;
    }) => {
      const { opacity } = context.tooltip;
      setTooltipData(context.tooltip);
      if (opacity === 0) {
        // Hide tooltip visibilty
        return setTooltipVisible(false);
      }

      const chart = chartRef.current;
      // @ts-ignore
      const canvas = chart?.canvas;
      if (!canvas) return;

      // Enable tooltip visibilty
      setTooltipVisible(true);

      // Set position of tooltip
      const left = context.tooltip.x;
      const top = context.tooltip.y;

      // Handle tooltip multiple rerender
      if (tooltipPos?.top !== top) {
        setTooltipPos({ top, left });
      }
    },
    [tooltipPos?.top]
  );

  const getNormalizedData = useCallback(
    <T extends TimeSelectOption["value"]>(
      timeFrame: T,
      resp: SuccessRateTypes
    ): (SuccessRateType & { date: string, backgroundAlpha: number })[] => {
      const dataToShow = resp[timeFrame];
      const fullSlotLength = new Date(dataToShow[1].time).getTime() - new Date(dataToShow[0].time).getTime();

      const data = dataToShow.map((x, idx, arr) => {
        const { value } = selectedTime;
        let opts: Intl.DateTimeFormatOptions = {};
        if (value === "days") {
          opts = { weekday: "long", day: "numeric" };
        }

        if (value === "hours") {
          opts = { weekday: "short", day: "numeric", hour: "2-digit", minute: "2-digit" };
        }

        if (value === "minutes") {
          opts = { hour: "2-digit", minute: "2-digit" };
        }

        let backgroundAlpha = 1;

        if (idx > 0) {
          const slotLength = new Date(x.time).getTime() - new Date(arr[idx - 1].time).getTime();
          backgroundAlpha = (slotLength > fullSlotLength ? slotLength - fullSlotLength : slotLength) / fullSlotLength;
        }

        return {
          ...x,
          date: new Intl.DateTimeFormat("en-US", opts).format(
            new Date(x.time)
          ),
          backgroundAlpha: backgroundAlpha
        };
      });
      return data;
    },
    [selectedTime]
  );

  const successData = useMemo(() => {
    if (!successRateData) return [];
    return getNormalizedData(selectedTime.value, successRateData);
  }, [getNormalizedData, selectedTime, successRateData]);

  const onDownloadCsvClick = useCallback(() => {
    const data = successData;

    const opts = { fields: ["date", "successes", "costs", "failures"] };
    try {
      const blob = new Blob([parse(data, opts)], {
        type: "text/csv;charset=utf-8"
      });
      saveAs(blob, "scraperapi-usage-export.csv");
    } catch (err) {
      console.error(err);
    }
  }, [successData]);

  // 2022-01-01
  const formatDateToYMD = (d: Date) => d.toISOString().slice(0,10);
  const domainReportFileName = () => {
    const now = new Date();
    const sixtyDaysAgo = new Date(now.getTime() - (60 * DAY));
    const reportName = `scraperapi_domainreport_${formatDateToYMD(sixtyDaysAgo)}_${formatDateToYMD(now)}.csv`;
    return reportName;
  }

  const onDownloadPerDomainCsvClick = async () => {
    // setReportInfoModalOpened(true);
    setGeneratingReportInProgress(true);
    const perDomainReport = await scraperApi.stats.perDomainReport();
    setGeneratingReportInProgress(false);
    try {
      const blob = new Blob([ perDomainReport ], {
        type: "text/csv;charset=utf-8;"
      });
      saveAs(blob, domainReportFileName());
    } catch (err) {
      console.error(err);
    }
  };

  const ChartData = useMemo(() => {
    return {
      labels: successData?.map(x => x.date) ?? [],
      datasets: [
        {
          label: "Success",
          data: successData?.map(({ successes }) => Number(successes)),
          backgroundColor: successData?.map(({ backgroundAlpha }) => `rgba(26, 34, 228, ${backgroundAlpha})`),
          barThickness: 25,
          barPercentage: 0.5,
          categoryPercentage: 0.75
        },
        {
          label: "Credit",
          data: successData?.map(({ costs }) => Number(costs)),
          backgroundColor: successData?.map(({ backgroundAlpha }) => `rgba(92, 171, 246, ${backgroundAlpha})`),
          barThickness: 25,
          barPercentage: 0.5,
          categoryPercentage: 0.75
        },
        {
          label: "Failure",
          data: successData?.map(({ failures }) => Number(failures)),
          backgroundColor: successData?.map(({ backgroundAlpha }) => `rgba(161, 179, 193, ${backgroundAlpha})`),
          barThickness: 25,
          barPercentage: 0.5,
          categoryPercentage: 0.75
        }
      ]
    };
  }, [successData]);

  const ChartOptions: ChartOptions<"bar"> = useMemo(() => {
    return {
      responsive: true,
      scales: { x: { grid: { display: false } } },
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: { family: "Work Sans" },
            boxHeight: 12,
            boxWidth: 12,
            padding: 30
          }
        },
        tooltip: {
          enabled: false,
          backgroundColor: "#fff",
          titleColor: "#000",
          borderColor: "#A1B3C1",
          borderWidth: 1,
          mode: "index",
          external: customTooltip
        }
      }
    };
  }, [customTooltip]);

  useEffect(() => {
    const controller = new AbortController();
    scraperApi.auth
      .logs({ signal: controller.signal })
      .then(setSuccessRateData);
    return () => controller.abort();
  }, []);

  return (
    <div className="relative p-8 bg-white border border-borderColor dark:border-neutral-200">
      <div className="flex justify-between w-full">
        <div className="flex items-center gap-x-4">
          <div className="text-gray dark:text-neutral-600 whitespace-nowrap">Monitoring & Stats</div>
          <Select
            value={ selectedTime }
            onChange={ setSelectedTime }
            options={ TimeSelectOptions }
            size="SM"
            className="w-[115px]"
          />
        </div>
        <div>
          <Menu>
            <MenuButton disabled={generatingReportInProgress}>
              <div className="border-lightGray dark:border-neutral-500 border p-2 h-full">
                { generatingReportInProgress
                ? <Spinner className="w-4 h-4 text-lightGray dark:text-neutral-500 animate-spin" />
                : <ReportsDropdownIcon/> }

              </div>
            </MenuButton>
            <MenuItems anchor={ { to: "bottom", gap: "0.5rem", offset: "-2rem" } } className="origin-top-right">
              <MenuItem>
                <div className="gap-5">
                  <button
                    className="block w-full transition-colors hover:bg-slate-200"
                    onClick={onDownloadCsvClick}
                  >
                    <div className="flex items-center gap-x-2 p-2">
                      <ChartStatsIcon/>
                      <span className="whitespace-nowrap">Chart stats</span>
                    </div>
                  </button>
                </div>
              </MenuItem>
              <MenuItem>
                <button
                  className="block w-full transition-colors hover:bg-slate-200"
                  onClick={onDownloadPerDomainCsvClick}
                >
                  <div className="flex items-center gap-x-2 p-2">
                    <DomainStatsIcon/>
                    <span className="whitespace-nowrap">Domain stats</span>
                  </div>
                </button>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>

      {tooltipPos && (
        <HTMLTooltip
          data={tooltipData}
          position={tooltipPos}
          visibility={tooltipVisible}
        />
      )}

      <Bar ref={chartRef} options={ChartOptions} data={ChartData} />
    </div>
  );
}
