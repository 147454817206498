import { ComponentType } from "react";

import { ReactComponent as CurlIcon } from "assets/icons/curl-icon.svg";
import { ReactComponent as NodeJSIcon } from "assets/icons/nodejs-icon.svg";
import { ReactComponent as PHPIcon } from "assets/icons/php-icon.svg";
import { ReactComponent as PythonIcon } from "assets/icons/python-icon.svg";

import Button from "components/Button";
import Card from "components/Card";
import ExtLink from "components/ExtLink";

import { useSupportWidget } from "hooks/useSupportWidget";

import { useUser } from "routes/dataroutes/UserData";


const docLinks: IDocLinkProps[] = [
  {
    text: "View NodeJS documentation",
    href: "https://docs.scraperapi.com/v/nodejs/making-requests/",
    Icon: NodeJSIcon
  },
  {
    text: "View Python documentation",
    href: "https://docs.scraperapi.com/v/python/making-requests/",
    Icon: PythonIcon
  },
  {
    text: "View cURL documentation",
    href: "https://docs.scraperapi.com/making-requests/",
    Icon: CurlIcon
  },
  {
    text: "View PHP documentation",
    href: "https://docs.scraperapi.com/v/php/making-requests/",
    Icon: PHPIcon
  }
];

interface IDocLinkProps {
  text: string;
  href: string;
  Icon: ComponentType<any>;
}

function DocLink(
  {
    text,
    href,
    Icon
  }: IDocLinkProps
) {
  return (
    <ExtLink
      to={ href }
      tracked
    >
      <div className="flex flex-row gap-x-3 items-center">
        <Icon className="w-11 h-11 text-neutral-500 bg-neutral-50 p-1.5 rounded border-neutral-50" />
        <div className="text-sm">{ text }</div>
      </div>
    </ExtLink>
  );
}

export default function NeedHelpCard(
  {
    className,
  }: {
    className?: string;
  }
) {
  const supportWidget = useSupportWidget();
  const user = useUser();

  return (
    <Card
      title="Need help?"
      className={ className }
    >
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-3">
          { docLinks.map((link, idx) => <DocLink key={ idx } { ...link } /> ) }
        </div>
        <div className="flex flex-row items-start gap-x-2">
          <Button
            text="View all docs"
            className="button button-secondary"
            size="SM"
            href="https://docs.scraperapi.com/documentation-overview"
            tracked
          />
          <Button
            text="Contact support"
            className="button button-tertiary"
            size="SM"
            onClick={ () => supportWidget.showSupportForm(user?.email) }
          />
        </div>
      </div>
    </Card>
  );
};
