import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useFetchers, useNavigation } from "react-router-dom";

import Button, { ButtonProps } from "components/Button";
import Spinner from "components/Spinner";

const SubmitButton = forwardRef(
  (
    {
      checkFormValidity,
      trackDOMChanges = false,
      ...props
    }: ButtonProps & {
      checkFormValidity?: boolean;
      trackDOMChanges?: boolean;
    },
    ref
  ) => {
    const navigation = useNavigation();
    const fetchers = useFetchers();

    const submitButtonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => submitButtonRef.current!, []);

    const [ isFormInvalid, setIsFormInvalid ] = useState(false);

    const submittingFetcher = fetchers.find(fetcher => fetcher.state === "submitting");
    const nonIdleFetcher = submittingFetcher ?? fetchers.find(fetcher => fetcher.state !== "idle");

    const updateIsFormInvalid = useCallback(() => {
      if (submitButtonRef?.current?.form) {
        setIsFormInvalid(!submitButtonRef.current.form.checkValidity());
      }
    }, [ submitButtonRef ]);

    useEffect(() => {
      if (submitButtonRef.current && submitButtonRef.current.form) {
        if (checkFormValidity) {
          setIsFormInvalid(!submitButtonRef.current.form.checkValidity());
          submitButtonRef.current.form.addEventListener("input", (event) => {
            setIsFormInvalid(!(event.currentTarget as HTMLFormElement).checkValidity());
          });
        }

        if (trackDOMChanges) {
          const mutationObserver = new MutationObserver(updateIsFormInvalid);
          mutationObserver.observe(submitButtonRef.current.form, {
            childList: true,
            subtree: true,
            attributes: true
          });
        }
      }
    }, [ checkFormValidity, trackDOMChanges, updateIsFormInvalid ]);

    const isDisabled = props.disabled || isFormInvalid || navigation?.state === "submitting" || nonIdleFetcher !== undefined;

    return <Button
      ref={ submitButtonRef }
      { ...props as ButtonProps }
      disabled={isDisabled}
      type="submit"
      icon={ ((navigation?.state === "submitting") || (submittingFetcher !== undefined)) ?
        {
          Icon: Spinner,
          absolute: props.icon?.absolute === undefined ? true : props.icon.absolute,
          position: props.icon?.position,
        } :
        undefined
      }
    />;
  });

export default SubmitButton;
