import { MouseEvent } from "react";
import { useRevalidator } from "react-router-dom";

import scraperApi from "api";

import Button from "components/Button";
import CopyToClipboardText from "components/CopyToClipboardText";
import StatefulLink from "components/StatefulLink";

import { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";

import { useUser } from "routes/dataroutes/UserData";


interface IOnClickProps {
  onClick?: (event?: MouseEvent) => void;
}

function MenuItem(
  {
    label,
    item
  }: {
    label?: string;
    item: string | JSX.Element;
  }
) {
  return (
    <div className="flex flex-col text-sm px-5 py-3 space-y-2">
      { label && <span className="text-lightGray dark:text-neutral-500">{ label }</span> }
      { typeof item === "string" ? <span className="text-gray dark:text-neutral-600">{ item }</span> : item }
    </div>
  );
}

export function AccountEmail() {
  const user = useUser();

  return <MenuItem
    label="Account Email"
    item={ user?.email || "" }
  />;
}

export function AccountApiKey() {
  const user = useUser();

  return <MenuItem
    label="API Key"
    item={ <CopyToClipboardText text={ user?.apiKey || "" } /> }
  />;
}

export function ChangePassword({ onClick }: IOnClickProps) {
  const user = useUser();

  return (
    <StatefulLink
        to="/change-password"
        addLocationToState
        className="block w-full transition-colors"
        hoverClassName="hover:bg-slate-200"
        onClick={ onClick }
    >
      <MenuItem item={ user?.hasPassword ? "Change Password" : "Set Password" } />
    </StatefulLink>
  );
}

export function ManageApiKeys({ onClick }: IOnClickProps) {
  const user = useUser();

  return (
    <StatefulLink
      to="/api-keys"
      addLocationToState
      className={"block w-full transition-colors"}
      hoverClassName="hover:bg-slate-200"
      disabled={user?.isBlocked || false}
      onClick={ onClick }
    >
      <MenuItem item="Manage API Keys" />
    </StatefulLink>
  );
}

export function UseOldDashboardDesign({ onClick }: IOnClickProps) {
  const { updateNewDashboardDesignSetting } = useNewDashboardDesign();

  return (
    <button
      className="w-full transition-colors hover:bg-slate-200 flex justify-start"
      onClick={ (event?: MouseEvent) => {
        updateNewDashboardDesignSetting("no");
        onClick?.(event);
      } }
    >
      <MenuItem item="Back to old design" />
    </button>
  );
}

export function Logout({ onClick }: IOnClickProps) {
  const revalidator = useRevalidator();

  return <MenuItem
    item={ (
      <div className="px-1">
        <Button
          text="Logout"
          className="button button-primary"
          size="SM"
          fullWidth
          onClick={ async () => {
            onClick?.();

            await scraperApi.auth.logout();
            revalidator.revalidate();
          } }
        />
      </div>
    ) }
  />;
}
