import { useMemo } from "react";

import ApiKeyTextField from "components/ApiKeyTextField";
import Button from "components/Button";
import Icons from "components/Icons";

import { sampleCode } from "components/hosted-scraping/apiPlaygroundTemplates";

import { ApiPlaygroundLocationState } from "routes/dashboard/ApiPlaygroundEditDetails";
import { useUser } from "routes/dataroutes/UserData";

import { FIXED_WIDTH } from "./constants";
import { QuickStartStep, useQuickStartContext } from "./QuickStartCard";
import { QuickStartCodeView } from "./QuickStartCodeView";
import LanguageSelect from "./LanguageSelect";


const codeViewContext: Omit<ApiPlaygroundLocationState, "language"> = {
  scrapingMethod: "api",
  collectorType: "async_urls",
  url: "http://httpbin.org/",
};

export default function ApiQuickStartSteps() {
  const user = useUser();

  const { programmingLanguage } = useQuickStartContext();

  const codeExample = useMemo(() => {
    return sampleCode(
      programmingLanguage,
      codeViewContext.scrapingMethod,
      codeViewContext.collectorType,
      codeViewContext.url,
      user?.apiKey || "",
      {}
    );
  }, [ programmingLanguage, user?.apiKey ]);


  return (
    <>
      <QuickStartStep
        title="Select your preferred programming language"
        index={ 2 }
      >
        <LanguageSelect />
      </QuickStartStep>
      <QuickStartStep
        title="Take a note of your API key"
        subtitle="Use the API key for every request. You can find & edit it in the right corner." // TODO "& edit"??? :O
        index={ 3 }
      >
        <div className={ FIXED_WIDTH }>
          <ApiKeyTextField className={ FIXED_WIDTH } />
        </div>
      </QuickStartStep>
      <QuickStartStep
        title="Test run your first request"
        subtitle="Copy the snippet, change the URL to scrape the page you want, and send your first request."
        index={ 4 }
      >
        <QuickStartCodeView language={ programmingLanguage } codeExample={ codeExample } canZoom />
      </QuickStartStep>
      <QuickStartStep
        title="Test run your request in our API Playground"
        subtitle="Learn more about all available parameters and the costs for your request, while scraping your URL in the API Playground."
        icon={ <Icons.APIPlayground className="h-4" /> }
      >
        <Button
          text="Go to API Playground"
          href="/apiplayground"
          size="SM"
          className="button button-accent"
          navigationState={
            {
              ...codeViewContext,
              language: programmingLanguage
            }
          }
        />
      </QuickStartStep>
    </>
  );
};
