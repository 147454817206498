import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ChildrenOrOutlet from "components/ChildrenOrOutlet";
import { IconName } from "components/Icons";

import {
  RecentlyViewedPage,
  useRecentlyViewed,
  useRecentlyViewedPage,
  useRecentlyViewedPages
} from "providers/RecentlyViewed/RecentlyViewedProvider";


function Tracker(
  {
    children,
  }: {
    children?: ReactNode
  }
) {
  const page = useRecentlyViewedPage();
  const routerLocation = useLocation();
  const recentlyViewedContext = useRecentlyViewed();
  const { changeRecentlyViewedPages } = useRecentlyViewedPages();

  useEffect(() => {
    if (page) {
      (recentlyViewedContext?.changeRecentlyViewedPages || changeRecentlyViewedPages)({
        action: "push",
        page: {
          ...page,
          location: page.location || routerLocation.pathname
        }
      });
    }
  }, [ page, routerLocation, changeRecentlyViewedPages, recentlyViewedContext ]);

  return <ChildrenOrOutlet children={ children } />;
}

export default function TrackRecentlyViewed(
  {
    location,
    title,
    iconName,
    children,
  }: {
    location?: string;
    title?: string;
    iconName?: IconName;
    children?: ReactNode;
  }
) {
  return (
    <RecentlyViewedPage
      location={ location }
      title={ title }
      iconName={ iconName }
    >
      <Tracker>{ children }</Tracker>
    </RecentlyViewedPage>
  );
};
