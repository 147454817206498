import _ from "lodash";

import { RecentlyViewedPageType } from "./RecentlyViewedProvider";
import { MAX_ENTRIES } from "./settings";


export function push(pages: RecentlyViewedPageType[], page: RecentlyViewedPageType) {
  if (pages.length < MAX_ENTRIES || _.some(pages, p => !p.pinned)) {
    // we still have some available slots
    const recentPageIdx = pages.findIndex(p => p.location === page.location);
    const firstUnpinnedIdx = pages.findIndex(p => !p.pinned);
    let pageToAdd = undefined;
    if (recentPageIdx < 0) {
      // not yet in the recently visited list
      pageToAdd = page;
    } else {
      // already in the recently visited list
      if (page.title) {
        pages[recentPageIdx].title = page.title;
      }
      if (page.iconName) {
        pages[recentPageIdx].iconName = page.iconName;
      }
      if ((recentPageIdx > 0) && !pages[recentPageIdx].pinned) {
        // not pinned, move it to the first unpinned position
        pageToAdd = pages[recentPageIdx];
        pages.splice(recentPageIdx, 1);
      }
    }

    if (pageToAdd) {
      // have to move the entry to the first unpinned position
      pages.splice(firstUnpinnedIdx, 0, pageToAdd);
      // shift all following pinned entries one place forward, to keep their original places
      for (let i = firstUnpinnedIdx + 1; i < pages.length - 1; ++i) {
        if (pages[i + 1].pinned) {
          const shifted = pages[i];
          pages[i] = pages[i + 1];
          pages[i + 1] = shifted;
        }
      }
      // make sure we don't exceed the maximum length
      if (pages.length > MAX_ENTRIES) {
        pages.pop();
      }
    }
  }
}

export function pin(pages: RecentlyViewedPageType[], page: RecentlyViewedPageType) {
  const recentPage = pages.find(p => p.location === page.location);
  if (recentPage) {
    recentPage.pinned = true;
  }
}

export function unpin(pages: RecentlyViewedPageType[], page: RecentlyViewedPageType) {
  const recentPage = pages.find(p => p.location === page.location);
  if (recentPage) {
    recentPage.pinned = false;
  }
}
