import Select, { SelectOption, SelectProps } from "components/Select";

import { cx } from "utils";


type SortByListBoxProps<T> = {
  buttonTestId?: string;
} & SelectProps<T>;

export function SortByListBox<T extends SelectOption<any>>(
  {
    value,
    options,
    onChange,
    className,
    buttonTestId
  }: SortByListBoxProps<T>
) {
  const appliedClassName = cx("flex flew-row items-center relative", className);
  return (

  <div className={appliedClassName}>
    <div className="text-nowrap white-norwap mr-2.5">Sort by</div>
    <Select
      size="SM"
      value={ value }
      options={ options }
      onChange={ onChange }
      buttonTestId={ buttonTestId }
      className="w-full text-nowrap"
    />
  </div>);
}
