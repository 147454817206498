import {
  MenuProps,
  Menu as HeadlessMenu,
  MenuButtonProps,
  MenuButton as HeadlessMenuButton,
  MenuItemsProps,
  MenuItems as HeadlessMenuItems,
} from "@headlessui/react";

import { MENU_AND_DROPDOWN_TRANSITION } from "misc/transitionProperties";

import { cx } from "utils";


export function MenuButton(
  {
    className,
    ...props
  }: MenuButtonProps
) {
  return (
    <HeadlessMenuButton
      className={ cx("cursor-pointer", className) }
      { ...props }
    />
  );
}

interface IMenuItemsProps extends Omit<MenuItemsProps, "transition"> {
  divided?: boolean;
}
export function MenuItems(
  {
    className,
    divided = false,
    ...props
  }: IMenuItemsProps
) {
  return (
    <HeadlessMenuItems
      transition
      className={ cx(
        className,
        "bg-white shadow-lg ring-1 ring-lightGray dark:ring-neutral-500 focus:outline-none",
        divided && "divide-y",
        MENU_AND_DROPDOWN_TRANSITION
      ) }
      { ...props }
    />
  );
}

export default function Menu(props: MenuProps) {
  return <HeadlessMenu { ...props }/>;
};
