import { Children, ReactElement, ReactNode } from "react";
import { Tab as TabTitle, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";

import { Sizes } from "styles/sizes";

import { cx } from "utils";


type TabsSizes = Required<Pick<Sizes<{ title: string }>, "SM" | "MD" | "LG">>;

const tabsSizes: TabsSizes = {
  SM: {
    title: "text-sm h-10",
  },
  MD: {
    title: "text-sm h-12"
  },
  LG: {
    title: "text-base h-14"
  }
};

export interface TabProps {
  title: string;
  children: ReactNode;
}

export function Tab(_props: TabProps) {
  return <></>;
}

function Title(
  {
    children,
  }: {
    children: ReactNode;
  }
) {
  return (
    <TabTitle
      as="div"
      className="h-full flex items-center cursor-pointer text-neutral-900 data-[selected]:text-primary-600 focus:outline-none border-b-2 border-b-transparent data-[selected]:border-b-primary-600"
    >
      { children }
    </TabTitle>
  );
}

interface TabsProps {
  size?: keyof typeof tabsSizes;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
}

export default function Tabs(
  {
    size = "MD",
    children
  }: TabsProps
) {
  const childrenArray = Children.toArray(children) as ReactElement<TabProps>[];

  return (
    <TabGroup className="flex flex-col gap-y-4 h-full">
      <TabList
        className={ cx(
          "flex flex-row items-center w-min text-nowrap gap-x-8 border-b-[1px] border-b-neutral-200",
          tabsSizes[size].title
        ) }
      >
        { childrenArray.map((tab, idx) =>
          <Title key={ idx }>{ tab.props.title }</Title>)
        }
      </TabList>
      <TabPanels className="flex-grow">
        { childrenArray.map((tab, idx) =>
          <TabPanel className="size-full" key={ idx }>{ tab.props.children }</TabPanel>)
        }
      </TabPanels>
    </TabGroup>
  );
};
