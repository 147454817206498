import { ReactNode } from "react";
import { Outlet, useOutletContext } from "react-router-dom";


export default function ChildrenOrOutlet({ children }: { children?: ReactNode }) {
  const outletContext = useOutletContext();

  return (
    <>
      { children ?? <Outlet context={ outletContext }/> }
    </>
  );
};
