import { useCallback, useEffect, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";

import { ReactComponent as AnnualArrowIcon } from "assets/icons/annual_discount_arrow.svg";

import Button from "components/Button";
import { useFeatureSwitch } from "components/FeatureSwitch";
import PricingPlan, { PricingPlanProps } from "components/PricingPlan";
import TaggedText from "components/TaggedText";

import Messages from "misc/Messages";

import {
  Plan,
  BillingPeriodUnit,
  RequiredUserContextType,
  useUserProvider
} from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";

import { cx, fmtDate } from "utils";
import {
  getPriceMultiplier,
  hasToContactUsBeforeChange,
  isAnnualPlan,
  isEnterprise,
  isUsingChargebeeCustomFields
} from "utils/planUtils";


type PlanSkeleton = {
  id: string;
  name: string;
  price?: number;
  discount_price?: number;
  period?: number;
  period_unit?: BillingPeriodUnit;
};

interface PlanPair {
  monthly?: Plan | PlanSkeleton;
  annual?: Plan | PlanSkeleton;
}

export type BillingPeriod = (keyof PlanPair) | "transitioning";


const getCustomEnterpriseFeatures = (plan: Plan) => {
  if (isUsingChargebeeCustomFields(plan.id)) {
    return plan;
  } else {
    return undefined;
  }
}


export interface PlanCardsListProps {
  planInActionIdx: number | undefined;
  setPlanInActionIdx: (idx: number | undefined) => void;
}

export default function PlanCardsList(
  {
    planInActionIdx,
    setPlanInActionIdx
  }: PlanCardsListProps
) {

  const user = useUser()!;
  const { subscription: activeSubscription, plans: allPlans } = useUserProvider() as RequiredUserContextType;
  const myPlanId = user.planSlug;
  const canHaveAffiliatePlan = user.canHaveAffiliatePlan;

  const scheduledSubscription = useMemo(() => {
    if (activeSubscription?.scheduledSubscription) {
      if (isUsingChargebeeCustomFields(activeSubscription.scheduledSubscription.plan_id)) {
        return {
          id: activeSubscription.scheduledSubscription.plan_id,
          name: activeSubscription.scheduledSubscription.name || "Enterprise",
          price: activeSubscription.scheduledSubscription.plan_unit_price,
          discount_price: activeSubscription.scheduledSubscription.discount_price,
          currency_code: activeSubscription.scheduledSubscription.currency_code,
          period: activeSubscription.scheduledSubscription.billing_period,
          period_unit: activeSubscription.scheduledSubscription.billing_period_unit,

          cf_api_request_limit: activeSubscription.scheduledSubscription.cf_api_request_limit,
          cf_api_concurrency_limit: activeSubscription.scheduledSubscription.cf_api_concurrency_limit,
          cf_api_geotargeting: activeSubscription.scheduledSubscription.cf_api_geotargeting
        };
      } else {
        return allPlans?.find(plan => plan.id === activeSubscription.scheduledSubscription?.plan_id);
      }
    }

    return undefined;
  }, [ activeSubscription, allPlans ]);

  const findPlanPair = useCallback((planSlug: string) => {
    return {
      monthly: allPlans?.find(p => p.id === planSlug),
      annual: allPlans?.find(p => p.id === ("annual_" + planSlug))
    };
  }, [ allPlans ]);

  const plansToShow = useMemo((): PlanPair[] => {
    const planPairs: PlanPair[] = [];

    let enterpriseCardAdded = false;


    if (myPlanId === "student_npo") {
      planPairs.push(findPlanPair("student_npo"));
    }

    if (myPlanId === "affiliate" || canHaveAffiliatePlan) {
      planPairs.push(findPlanPair("affiliate"));
    }

    if ((myPlanId === "hobby") || (myPlanId === "hobby_1")) {
      planPairs.push(findPlanPair("hobby_1"));
    } else {
      planPairs.push(findPlanPair("hobby_2"));
    }

    if (myPlanId === "startup") {
      planPairs.push(findPlanPair("startup"));
    } else {
      planPairs.push(findPlanPair("startup_2"));
    }

    planPairs.push(findPlanPair("business_2"));

    if ((myPlanId === "professional_1") || (myPlanId === "annual_professional_1")) {
      planPairs.push(findPlanPair("professional_1"));
    }

    if (myPlanId && (myPlanId === activeSubscription?.plan_id) && (isUsingChargebeeCustomFields(myPlanId))) {
      planPairs.push({
        monthly: {
          id: myPlanId,
          name: activeSubscription.name || "Enterprise",
          price: activeSubscription.plan_unit_price,
          discount_price: activeSubscription.discount_price,
          currency_code: activeSubscription.currency_code,
          period: activeSubscription.billing_period,
          period_unit: activeSubscription.billing_period_unit,
          cf_api_request_limit: activeSubscription.cf_api_request_limit,
          cf_api_concurrency_limit: activeSubscription.cf_api_concurrency_limit,
          cf_api_geotargeting: activeSubscription.cf_api_geotargeting
        }
      });
      enterpriseCardAdded = true;
    }

    if (scheduledSubscription && (scheduledSubscription.id !== myPlanId) && isUsingChargebeeCustomFields(scheduledSubscription.id)) {
      planPairs.push({
        monthly: {
          id: scheduledSubscription.id,
          name: scheduledSubscription.name,
          price: scheduledSubscription.price,
          discount_price: scheduledSubscription.discount_price,
          currency_code: scheduledSubscription.currency_code,
          period: scheduledSubscription.period,
          period_unit: scheduledSubscription.period_unit,
          cf_api_request_limit: scheduledSubscription.cf_api_request_limit,
          cf_api_concurrency_limit: scheduledSubscription.cf_api_concurrency_limit,
          cf_api_geotargeting: scheduledSubscription.cf_api_geotargeting
        }
      });
      enterpriseCardAdded = true;
    }

    if (!enterpriseCardAdded && allPlans) {
      planPairs.push({
        monthly: {
          id: "enterprise",
          name: "Enterprise",
        }
      });
    }

    // set monthly prices for annual subscriptions
    for (const planPair of planPairs) {
      if (planPair.monthly && planPair.annual) {
        const multiplier = getPriceMultiplier(planPair.monthly.period || 1, planPair.monthly.period_unit || "month", planPair.annual.period || 1, planPair.annual.period_unit || "month");
        if (!planPair.annual.discount_price) {
          planPair.annual.discount_price = planPair.annual.price!;
        }
        planPair.annual.price = (planPair.monthly.price! * multiplier);
      }
    }

    return planPairs;
  }, [ myPlanId, activeSubscription, scheduledSubscription, findPlanPair, allPlans, canHaveAffiliatePlan ]);

  const getPlan = useCallback((planSlug: string | undefined) => {
    if (planSlug) {
      const planPair = plansToShow.find(planPair => (planPair.monthly?.id === planSlug) || (planPair.annual?.id === planSlug));
      if (planPair) {
        return planPair.monthly?.id === planSlug ? planPair.monthly : planPair.annual;
      }
    }

    return undefined;
  }, [ plansToShow ]);

  const getPlanIdx = useCallback((planSlug: string | undefined) => {
    const idx = planSlug ? (plansToShow.findIndex(planPair => ((planPair.monthly?.id === planSlug) || (planPair.annual?.id === planSlug))) + 1) : -1;
    return idx * (isAnnualPlan(planSlug) ? 100 : 1);
  }, [ plansToShow ]);

  const myPlan = useMemo(() => {
    return getPlan(user.planSlug);
  }, [ user, getPlan ]);

  const myPlanIdx = useMemo(() => {
    return getPlanIdx(myPlan?.id);
  }, [ getPlanIdx, myPlan ]);

  const getPricingPlanPropsFor = useCallback((plan: Plan | PlanSkeleton) => {
    const outOfSync = (myPlan && activeSubscription && (myPlan.id !== activeSubscription.plan_id)) || false;
    const renewing = Boolean(activeSubscription?.last_renewal_attempt);
    const scheduledCancellation = activeSubscription?.scheduledSubscription?.plan_id === "free";
    const pendingChanges = outOfSync || renewing;

    const planIdx = getPlanIdx(plan.id);

    const props: PricingPlanProps = {
      name: plan.name,
      price: plan.discount_price || plan.price,
      originalPrice: plan.price,
      period: plan.period || 1,
      periodUnit: plan.period_unit,
      planSlug: plan.id,
      isAnnual: isAnnualPlan(plan.id),
      planAutoRenewal: user.planAutoRenewal,
      theme: "secondary",
      buttonAction: "contact_sales",
      buttonClassName: "button button-secondary",
      planIdx,
      planInActionIdx,
      setPlanInActionIdx
    };

    if (plan.id === myPlan?.id) {
      // rendering the active plan (the one in our database)

      props.customPlanOverride = getCustomEnterpriseFeatures(myPlan);

      // user has unpaid invoices
      if (user.hasPaymentIssues) {
        props.buttonAction = "pay_now";
        props.buttonClassName = "button button-primary destructive";
        props.bannerLabel = "Outstanding invoices";

        return props;
      }

      props.buttonAction = "renew";

      if (!user.isRenewalAllowed) {
        props.buttonDisabled = true;
        props.buttonClassName = "button button-primary";
        props.buttonTooltip = <TaggedText message={ Messages.renewalDisabledTaggedMessage } />
        props.buttonTooltipClickable = true;
      } else if (scheduledCancellation) {
        props.theme = "primary";
        props.buttonClassName = "button button-primary";
        props.bannerLabel = "Cancelling on " + fmtDate(new Date((activeSubscription?.current_term_end || 0) * 1000));
      } else if (pendingChanges) {
        // pending changes (chargebee and database out of sync)
        // TODO props.theme = "warning";  -- we only have ERROR theme (destructive)

        if (renewing) {
          props.bannerLabel = "Renewing now";
        } else {
          const chargebeePlanIdx = getPlanIdx(activeSubscription?.plan_id);
          props.bannerLabel = (chargebeePlanIdx < 0 ? "Change" : ((myPlanIdx < chargebeePlanIdx) ? "Upgrade" : "Downgrade")) + " in progress";
        }
        props.buttonDisabled = true;
      } else {
        props.theme = "primary";
        props.buttonClassName = "button button-primary";
      }
    } else {
      // rendering a different plan

      const planIdx = getPlanIdx(plan.id);
      if (plan.id === scheduledSubscription?.id) {
        const scheduledPlanIdx = getPlanIdx(scheduledSubscription.id);
        props.theme = "primary";
        if (isUsingChargebeeCustomFields(plan.id)) {
          props.customPlanOverride = getCustomEnterpriseFeatures(scheduledSubscription);
          props.bannerLabel = "Scheduled upgrade";
        } else {
          props.buttonAction = "cancel_change";
          props.bannerLabel = "Scheduled " + ((scheduledPlanIdx < myPlanIdx) ? "downgrade" : "upgrade");
        }
      } else if (planIdx >= 0) {
        if (planIdx < myPlanIdx) {
          props.buttonAction = hasToContactUsBeforeChange(myPlan?.id) ? "downgrade_enterprise" : (isAnnualPlan(activeSubscription?.plan_id) ? "downgrade_annual" : "downgrade");
        } else {
          props.buttonAction = hasToContactUsBeforeChange(plan?.id) ? "contact_sales" : (isAnnualPlan(activeSubscription?.plan_id) ? "upgrade_annual" : "upgrade");
          if (isEnterprise(plan?.id) && !isAnnualPlan(plan?.id)) {
            props.bannerLabel = "SAVE 10% ON YEARLY PLAN";
          }
        }
      }

      if (
        user.hasPaymentIssues ||
        pendingChanges ||
        (user.isBlocked && (user.blockingCode !== "banned_from_free" || (![ "contact_sales", "upgrade", "upgrade_annual" ].includes(props.buttonAction))))
      ) {
        props.buttonDisabled = true;
      }
    }

    return props;
  }, [ myPlan, myPlanIdx, activeSubscription, scheduledSubscription, user, getPlanIdx, planInActionIdx, setPlanInActionIdx ]);

  const showAnnualUserInList = useFeatureSwitch("REACT_APP_ANNUAL_PLANS_USERS");
  const showAnnualNewUser = useFeatureSwitch("REACT_APP_ANNUAL_PLANS_NEW_SIGNUPS_AFTER");
  const showAnnualPrices = isAnnualPlan(user.planSlug) || showAnnualUserInList || showAnnualNewUser;

  const monthlyOrAnnual = localStorage.getItem("monthlyOrAnnualBilling") as BillingPeriod || "monthly";
  const [ showingPrices, setShowingPrices ] = useState<BillingPeriod>(monthlyOrAnnual);
  const [ activeButton, setActiveButton ] = useState<BillingPeriod>(monthlyOrAnnual);

  useEffect(() => {
    localStorage.setItem("monthlyOrAnnualBilling", activeButton);
  }, [ activeButton ]);

  return (
    <>
      { showAnnualPrices && (
        <div className="flex flex-col gap-y-3">
          <div className="flex justify-end gap-x-1.5">
            <AnnualArrowIcon className="translate-y-2.5"/>
            <span className="text-sm text-gray dark:text-neutral-600">10% off</span>
          </div>
          <div className="flex justify-between">
            <span className="text-lg text-brandDarkest dark:text-primary-800">Plans & pricing</span>
            <div className="flex flex-row">
              <Button
                text="Monthly billing"
                className={ cx("button", activeButton === "monthly" ? "button-primary" : "button-secondary") }
                focusable={false}
                onClick={() => {
                  if (showingPrices === "annual") {
                    setActiveButton("monthly");
                    setShowingPrices("transitioning");
                  }
                }}
              />
              <Button
                text="Annual billing"
                className={ cx("button", activeButton === "annual" ? "button-primary" : "button-secondary") }
                focusable={false}
                onClick={() => {
                  if (showingPrices === "monthly") {
                    setActiveButton("annual");
                    setShowingPrices("transitioning");
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="grid lg:grid-cols-[repeat(auto-fit,minmax(250px,1fr))] auto-rows-[1fr] gap-4">
        {
          allPlans &&
          plansToShow.map((planPair) => {
            if (planPair.monthly) {
              const transitionClassName = cx(
                "transition",
                "data-[closed]:opacity-0",
                "data-[enter]:duration-500 data-[enter]:data-[closed]:scale-y-50",
                "data-[leave]:duration-300 data-[leave]:data-[closed]:scale-y-[.2]"
              );

              return (
                <div key={planPair.monthly.id} className="min-w-[250px] min-h-[385px] h-full">
                  {(showAnnualPrices && planPair.annual) && (
                    <>
                      {/* TODO transition properties have been moved from the <Transition> component to data attributes of the child components */}
                      <Transition
                        show={showingPrices === "monthly"}
                        afterLeave={() => setShowingPrices("annual")}
                      >
                        <div className={ transitionClassName }>
                          <PricingPlan {...getPricingPlanPropsFor(planPair.monthly)} />
                        </div>
                      </Transition>
                      <Transition
                        show={showingPrices === "annual"}
                        afterLeave={() => setShowingPrices("monthly")}
                      >
                        <div className={ transitionClassName }>
                          <PricingPlan {...getPricingPlanPropsFor(planPair.annual)} />
                        </div>
                      </Transition>
                    </>
                  )}

                  {(!showAnnualPrices || !planPair.annual) && (
                    <PricingPlan {...getPricingPlanPropsFor(planPair.monthly)} />
                  )}
                </div>
              );
            } else {
              return <></>;
            }
          })
        }
      </div>
    </>
  );

};
