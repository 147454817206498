import { MouseEvent, MouseEventHandler, ReactNode, useCallback } from "react";

import {
  useRecentlyViewed,
  useRecentlyViewedPage,
  useRecentlyViewedPages
} from "providers/RecentlyViewed/RecentlyViewedProvider";


export default function ExtLink({
  className,
  to,
  id,
  onClick,
  disabled,
  children,
  tracked = false,
  trackedTitle,
}: {
  className?: string;
  to: string;
  id?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean
  children: ReactNode;
  tracked?: boolean;
  trackedTitle?: string;
}) {
  const recentlyViewedContext = useRecentlyViewed();
  const { changeRecentlyViewedPages, isTracked } = useRecentlyViewedPages();
  const recentlyViewedPage = useRecentlyViewedPage();

  const clickHandler = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    if (isTracked(to)) {
      (recentlyViewedContext?.changeRecentlyViewedPages || changeRecentlyViewedPages)({
        action: "push",
        page: {
          ...recentlyViewedPage,
          location: to,
          pinned: false,
          title: trackedTitle || recentlyViewedPage?.title
        }
      });
    }

    return onClick?.(event);
  }, [ isTracked, onClick, to, recentlyViewedContext, changeRecentlyViewedPages, recentlyViewedPage, trackedTitle ]);


  if (disabled) {
    return <span className={ className }>
      {children}
    </span>
  }
  return (
    <a
      className={ className }
      target="_blank"
      id={id}
      rel="noreferrer"
      href={to}
      onClick={ tracked ? clickHandler : onClick }
    >
      {children}
    </a>
  );
}
