import { createContext, Dispatch, ReactNode, useContext, useEffect, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import _ from "lodash";

import { IconName } from "components/Icons";

import { useLocalStorage } from "hooks/useLocalStorage";

import * as actions from "./actions";
import { trackedExternalLinks } from "./settings";


type Setter<T> = (arg: T) => void;

function ChildrenOrOutlet({ children }: { children?: ReactNode }) {
  const outletContext = useOutletContext();
  return <>{ children ?? <Outlet context={ outletContext }/> }</>
}

export type RecentlyViewedPageType = {
  location: string | undefined;
  title?: string;
  iconName?: IconName;
  pinned: boolean;
};

type RecentlyViewedPageContextType = RecentlyViewedPageType & {
  setLocation: Setter<string>;
  setTitle: Setter<string>;
  setIconName: Setter<IconName>;
  setPinned: Setter<boolean>;
};

const RecentlyViewedPageContext = createContext<RecentlyViewedPageContextType | null>(null);

export function RecentlyViewedPage(
  {
    location,
    title,
    iconName,
    pinned = false,
    children,
  }: {
    location?: string;
    title?: string;
    iconName?: IconName;
    pinned?: boolean;
    children?: ReactNode;
  }
) {
  const [ pageLocation, setPageLocation ] = useState<string | undefined>(location);
  const [ pageTitle, setPageTitle ] = useState<string | undefined>(title);
  const [ pageIconName, setPageIconName ] = useState<IconName | undefined>(iconName);
  const [ pagePinned, setPagePinned ] = useState<boolean>(pinned);

  return <RecentlyViewedPageContext.Provider
    value={ {
      location: pageLocation,
      setLocation: setPageLocation,
      title: pageTitle,
      setTitle: setPageTitle,
      iconName: pageIconName,
      setIconName: setPageIconName,
      pinned: pagePinned,
      setPinned: setPagePinned,
    } }
  >
    <ChildrenOrOutlet children={ children } />
  </RecentlyViewedPageContext.Provider>
}

export function useRecentlyViewedPage() {
  return useContext(RecentlyViewedPageContext);
}

type RecentlyViewedAction = {
  action: "push" | "pin" | "unpin";
  page: RecentlyViewedPageType;
};

function updateRecentlyViewedPages(pages: RecentlyViewedPageType[], action: RecentlyViewedAction) {
  return actions[action.action](pages, action.page);
}

type RecentlyViewedContextType = {
  recentlyViewedPages: RecentlyViewedPageType[];
  changeRecentlyViewedPages: Dispatch<RecentlyViewedAction>;
  isTracked: Dispatch<string>;
};

const RecentlyViewedContext = createContext<RecentlyViewedContextType | null>(null);

export function useRecentlyViewedPages() {
  const [ pagesInLocalStorage, setPagesInLocalStorage ] = useLocalStorage<RecentlyViewedPageType[]>("saRecentlyViewed", []);
  const [ recentlyViewedPages, changeRecentlyViewedPages ] = useImmerReducer(updateRecentlyViewedPages, pagesInLocalStorage);

  useEffect(() => {
    // this check is needed here because the immerReducer returns a new object every time, thus changing references, so even setting the same array would lead to an endless loop
    if (!_.isEqual(recentlyViewedPages, pagesInLocalStorage)) {
      setPagesInLocalStorage(recentlyViewedPages);
    }
  }, [ recentlyViewedPages, pagesInLocalStorage, setPagesInLocalStorage ]);

  return { recentlyViewedPages, changeRecentlyViewedPages, isTracked };
}

function isTracked(location: string) {
  return trackedExternalLinks.some(loc => location.startsWith(loc));
}

export function RecentlyViewedProvider({ children }: { children?: ReactNode }) {
  const { recentlyViewedPages, changeRecentlyViewedPages } = useRecentlyViewedPages();

  return (
    <RecentlyViewedContext.Provider value={ { recentlyViewedPages, changeRecentlyViewedPages, isTracked } }>
      <ChildrenOrOutlet children={ children }/>
    </RecentlyViewedContext.Provider>
  );
}

export function useRecentlyViewed() {
  return useContext(RecentlyViewedContext);
}
